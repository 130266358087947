/**
 * Exporting all the variables depending if we are in develop or in a brand specific branch
 */

module.exports = {
  logo: 'siloy.svg',
  logoalt: 'siloy-alt.svg',
  logoShare: 'bloqhouse.png',
  scssVariables: './src/scss/variables/brands/_siloy.scss',
  name: 'Siloy Group B.V.',
  companyName: 'Wave Corporate Finance B.V.',
  phone: '+31622559814',
  email: 'info@wavecorporatefinance.com',
  website: 'https://www.wavecorporatefinance.com',
  mainClientName: 'wave',
  clientName: 'siloy',
  glossary: true,
  // langFileName: 'nlc.ts',
  modules: {
    landing: false,
    identification: true, // setting this to false requires manual changes
  },
  secondFooter: {
    name: 'wave',
    href: 'https://www.wavecorporatefinance.com',
  },
  intercom: false,
  requireContractAgreement: true,
  requireQuestionnaire: false,
  requireLegalBanner: true,
  chartColors: {
    greys: {
      color1: '#b1b3bd',
      color2: '#f7f7f7',
    },
    projectColors: {
      color1: '#90d1bb',
      color2: '#addacb',
      color3: '#82bda9',
      color4: '#96d9c2',
    },
  },
  language: 'nl',
  defaultCurrency: {
    name: 'euro',
    symbol: '€',
  },
  currencyTranslations: {
    depositoryReceipts: {
      nl: 'Cert.',
      en: 'DRs',
    },
  },
  showAnimations: true,
  dashboard: {
    transanctionsCollapse: false,
  },
  entity: {
    showCharts: false,
  },
};
